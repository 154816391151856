import React, { useState, useEffect, useReducer } from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import "../styles/showcase.scss"
import { ImageMockup } from "./ImageMockup"
import useWindowSize from "../hooks/useWindowSize"
const QUERY = graphql`
  query GET_PROJECTS {
    allMdx(
      filter: { frontmatter: { published: { eq: true } } }
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            tags
            path {
              publicURL
              extension
              childImageSharp {
                img: fluid(maxWidth: 500) {
                  ...GatsbyImageSharpFluid
                  src
                }
              }
            }
          }
        }
      }
    }
  }
`
export default function Showcase() {
  const [currrentPlaying, setCurrrentPlaying] = useState(null)
  const [projects, setProjects] = useState(useStaticQuery(QUERY))
  const [loadMore, setLoadMore] = useState(false)
  const [limit, setLimit] = useState(4)
  const { width, height } = useWindowSize()
  if (!projects) {
    return <h2>Loading</h2>
  }
  if (width > 700) {
    if (projects && limit !== projects.allMdx.edges.length) {
      setLimit(projects.allMdx.edges.length)
    }
  }
  return (
    <div className="grid grid-cols-1 gap-3 sm:grid-cols-2 lg:grid-cols-3">
      {projects &&
        projects.allMdx.edges.slice(0, limit).map(({ node }, index) => {
          const { title } = node.frontmatter
          return (
            <ImageMockup
              key={node.id}
              slug={node.fields.slug}
              src={node.frontmatter.path.childImageSharp.img}
              title={title}
              i={index}
              tags={node.frontmatter.tags}
            />
          )
        })}
      {projects && limit !== projects.allMdx.edges.length && width <= 700 && (
        <div
          className="button gradient-primary sm:col-span-2 md:hidden"
          onClick={() => setLimit(projects.allMdx.edges.length)}
        >
          {projects && "See " + Number(projects.allMdx.edges.length - limit)}{" "}
          More Projects
        </div>
      )}
    </div>
  )
}
