import React, { useState } from "react"
import Video from "./Video"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { Play, Pause } from "./svg-components"
const Hey = () => {
  return <div>Hey</div>
}
export default function VideoMockup({
  id,
  src,
  title,
  tags,
  playing,
  setPlaying,
  slug,
}) {
  return (
    <div className="showcase__item">
      <div className="mockup__container">
        <Video src={src} playing={playing} setPlaying={setPlaying} id={id}>
          <Play />
          <Pause />
        </Video>
        {title && <Desc title={title} tags={tags} slug={slug} />}
      </div>
    </div>
  )
}
export const Desc = ({ title, tags, tech, slug }) => {
  return (
    <div className="p-5 rounded-b-lg h-full bg-background-card">
      <div className="flex flex-col justify-between">
        <h2 className="text-base md:text-xl text-text-secondary1">{title}</h2>
        <p className="text-sm font-medium md:text-lg text-text-secondary2">
          {tags && tags}
        </p>
      </div>
      {/* <div className="mockup__tech">React, Express</div> 
      <button
      style={{ width: "100%", padding: "1.5rem 4rem", background: "#02aab0" }}
      >
      Live
      </button>
    */}
    </div>
  )
}
VideoMockup.propTypes = {
  src: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  tags: PropTypes.string.isRequired,
  playing: PropTypes.bool.isRequired,
  setPlaying: PropTypes.func.isRequired,
}
