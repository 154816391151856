import React from "react"
import "../styles/footer.scss"
import Container from "./Container"
import {
  LinkedInIcon,
  TwitterIcon,
  InstagramIcon,
  DribbbleIcon,
  GithubIcon,
} from "./SocialMediaIcons"
export default function Footer() {
  const loading = false
  return (
    <footer className="py-12 bg-background-footer">
      <Container>
        <div className="flex flex-col items-center justify-between md:flex-row">
          <div className="flex flex-col items-center justify-center text-white md:items-baseline md:justify-start md:flex-row">
            <h2 className="text-xl font-medium text-center md:text-left sm:text-2xl md:text-3xl lg:text-4xl">
              Let's Connect
            </h2>
            <span className="mr-5"></span>
            <p className="text-base font-normal lg:text-2xl font-nanum">
              Would love to have a chat!
            </p>
          </div>
          <div className="flex items-center justify-center mt-4 md:justify-start">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.linkedin.com/in/anurag-bhagsain/"
            >
              <LinkedInIcon />
            </a>
            <span className="mr-3"></span>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://github.com/abhagsain"
            >
              <GithubIcon />
            </a>
            <span className="mr-3"></span>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://twitter.com/abhagsain"
            >
              <TwitterIcon />
            </a>
            <span className="mr-3"></span>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.instagram.com/657885_/"
            >
              <InstagramIcon />
            </a>
            <span className="mr-3"></span>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://dribbble.com/abhagsain"
            >
              <DribbbleIcon />
            </a>
          </div>
        </div>
      </Container>
    </footer>
  )
}

{
  /* <div className="footer">Built With ❤ By Anurag Bhagsain</div> */
}
