import React, { useState } from "react"
import { motion } from "framer-motion"
export default class AnimatedItem extends React.Component {
  constructor(props) {
    super(props)
    this.state = { wasVisible: false }
  }
  componentDidMount() {
    const { inViewport } = this.props
    const { wasVisible } = this.state
    if (inViewport) {
      if (!wasVisible) this.setState({ wasVisible: true })
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.props.inViewport) {
      if (!this.state.wasVisible) this.setState({ wasVisible: true })
    }
  }

  // static getDerivedStateFromProps(props, state) {
  // console.log("TCL: AnimatedItem -> getDerivedStateFromProps -> props", props)
  // console.log("TCL: AnimatedItem -> getDerivedStateFromProps -> state", state)
  // }
  // shouldComponentUpdate(nextProps, nextState) {
  //   console.log(
  //     "TCL: AnimatedItem -> shouldComponentUpdate -> nextState",
  //     nextState
  //   )
  //   console.log(
  //     "TCL: AnimatedItem -> shouldComponentUpdate -> nextProps",
  //     nextProps
  //   )
  // }
  render() {
    const variants = {
      visible: i => ({
        opacity: 1,
        y: 0,
        transition: {
          delay: i * 0.1,
        },
      }),
      hidden: { opacity: 0, y: 6 },
    }
    const { wasVisible } = this.state
    const { children, forwardedRef, i, inViewport } = this.props
    return (
      <motion.div
        variants={variants}
        custom={i}
        animate={inViewport ? "visible" : !wasVisible ? "hidden" : "visible"}
        ref={forwardedRef}
        initial={"hidden"}
      >
        {children}
      </motion.div>
    )
  }
}
