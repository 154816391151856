import React, { useState } from "react"
import Img from "gatsby-image"
import PropTypes from "prop-types"
import { Desc } from "./VideoMockup"
import { Link } from "gatsby"
import handleViewport from "react-in-viewport"
import AnimatedItem from "./AnimatedItem"
const ImageContainer = ({
  inViewport,
  forwardedRef,
  i,
  src,
  title,
  tags,
  slug,
}) => {
  return (
    <AnimatedItem inViewport={inViewport} i={i} forwardedRef={forwardedRef}>
      <div className="h-full transition-shadow duration-300 rounded-lg shadow-md hover:shadow-xl">
        <Link to={slug} className="h-full overflow-hidden rounded-lg">
          <div className="flex flex-col w-full h-full">
            <div className="relative w-full pb-4/6">
              <Img
                fluid={src}
                alt=""
                style={{
                  height: "100%",
                  position: "absolute",
                }}
                className="inset-0 w-full h-full rounded-t-lg"
              />
            </div>
            {<Desc title={title} tags={tags} slug={slug} />}
          </div>
        </Link>
      </div>
    </AnimatedItem>
  )
}
const SlideUpImage = handleViewport(ImageContainer)
export const ImageMockup = ({ ...args }) => {
  return <SlideUpImage {...args} />
}
const fluidObject = PropTypes.shape({
  aspectRatio: PropTypes.number.isRequired,
  src: PropTypes.string.isRequired,
  srcSet: PropTypes.string.isRequired,
  sizes: PropTypes.string.isRequired,
  base64: PropTypes.string,
  tracedSVG: PropTypes.string,
  srcWebp: PropTypes.string,
  srcSetWebp: PropTypes.string,
  media: PropTypes.string,
})
ImageMockup.propTypes = {
  src: PropTypes.oneOfType([fluidObject, PropTypes.arrayOf(fluidObject)]),
  title: PropTypes.string.isRequired,
  tags: PropTypes.string.isRequired,
}
