/* eslint-disable react/no-unescaped-entities */
import React, { useRef } from "react"
import Container from "./Container"
import "../styles/hero.scss"
import Img from "gatsby-image"
import dots from "../dots.svg"
export default function Hero({ src }) {
  const button = useRef(null)
  const handleClick = () => {
    button.current.classList.remove("cta")
    document.querySelector(".psuedo__title").scrollIntoView({
      behavior: "smooth",
    })
  }
  const dotsStyles = {
    left: {
      left: "5%",
    },
    right: {
      top: "20%",
    },
  }
  return (
    <Container>
      <div className="flex">
        <div className="flex flex-col-reverse items-center justify-between w-full mt-5 md:flex-row md:my-40 ">
          <div className="mt-10 text-center md:text-left text-text-primary md:mt-0 lg:mr-40 ">
            <h2 className="text-lg font-medium sm:text-2xl lg:text-3xl xl:text-4xl">
              Hi, My name is Anurag
            </h2>
            <h2 className="text-xl font-medium xs:text-2xl sm:text-3xl lg:text-4xl xl:text-5xl font-body">
              I'm a Frontend Engineer
            </h2>
            <img
              src={dots}
              alt="dots"
              // style={{ left: "20%" }}
              srcSet={dots}
              className="absolute left-0 pointer-events-none max-w-xxs lg:-ml-24 top-90 lg:top-70 md:max-w-xs md:bottom-0 lg:max-w-md"
            />
            <img
              src={dots}
              alt="dots"
              srcSet={dots}
              className="absolute right-0 pointer-events-none max-w-xxs top-15 md:max-w-xs lg:top-20 lg:max-w-md"
            />
            <div className="mt-5 sm:mt-8"></div>
            <a
              href="https://drive.google.com/file/d/1H7UNc574uTeryeqm1rDr1m5RaoArkGm9/view"
              className="button gradient-primary hero-button"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                fontWeight: "normal",
                display: "inline-flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <span style={{ marginRight: ".5rem", alignSelf: "end" }}>
                Resume
              </span>
              <svg
                className="text-gray-100 fill-current open__window"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M11 7a1 1 0 110 2H5v10h10v-6a1 1 0 112 0v6a2 2 0 01-2 2H5a2 2 0 01-2-2V9a2 2 0 012-2h6z"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M19 6.414L8.707 16.707a1 1 0 01-1.414-1.414L17.586 5H14a1 1 0 110-2h6a1 1 0 011 1v6a1 1 0 11-2 0V6.414z"
                />
              </svg>
            </a>
          </div>
          <div className="w-full md:-mt-32 max-w-xxs md:max-w-xs xl:max-w-sm">
            {<Img fluid={src} alt="Anurag Bhagsain" srcSet={src} />}
          </div>
        </div>
      </div>
    </Container>
  )
}
