import React from "react"
import Container from "./Container"
import "../styles/about.scss"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"
import { SectionHeading, Nanum } from "./Projects"
const SVG = () => (
  <svg
    viewBox="0 0 30 30"
    height="56.7"
    width="56.7"
    xmlns="http://www.w3.org/2000/svg"
    fillRule="evenodd"
    clipRule="evenodd"
    strokeLinejoin="round"
    strokeMiterlimit="2"
  >
    <path
      d="M161.014 464.013c193.208 0 298.885-160.071 298.885-298.885 0-4.546 0-9.072-.307-13.578A213.737 213.737 0 00512 97.176a209.705 209.705 0 01-60.334 16.527 105.426 105.426 0 0046.182-58.102 210.548 210.548 0 01-66.703 25.498 105.184 105.184 0 00-76.593-33.112c-57.682 0-105.145 47.464-105.145 105.144 0 8.002.914 15.979 2.722 23.773-84.418-4.231-163.18-44.161-216.494-109.752-27.724 47.726-13.379 109.576 32.522 140.226A104.258 104.258 0 0120.48 194.23v1.331c.014 49.814 35.447 93.111 84.275 102.974a104.898 104.898 0 01-47.431 1.802c13.727 42.685 53.311 72.108 98.14 72.95a210.83 210.83 0 01-130.458 45.056A213.688 213.688 0 010 416.827a297.42 297.42 0 00161.014 47.104"
      fillRule="nonzero"
    />
  </svg>
)
export default function About({ src }) {
  const d = useStaticQuery(graphql`
    query GET_DESIGNATION {
      site {
        siteMetadata {
          designation
        }
      }
    }
  `)
  return (
    <section className="py-10 bg-background-about lg:py-25">
      <Container>
        <div className="">
          <SectionHeading>About Me</SectionHeading>
          <div className="mb-10"></div>
          <div className="relative">
            <div
              className="absolute hidden text-xs text-center lg:text-xl text-text-secondary1 font-nanum md:block"
              style={{
                top: " -50px",
                right: "8%",
                // transform: "translateX(-50%)",
              }}
            >
              {/* <Nanum>This is how I smile when I fix a bug. JK :)</Nanum> */}
            </div>
            <div className="flex flex-col-reverse justify-center md:flex-row">
              <div className="mx-auto text-center md:text-left sm:max-w-lg md:max-w-full md:w-1/2">
                <p className="text-lg font-normal sm:text-xl lg:text-2xl xl:text-2-5xl text-text-secondary1">
                  Hi 👋 I'm Anurag Bhagsain <br /> A Frontend Engineer currently
                  working at Treebo.
                </p>
                <p className="mt-6 text-lg font-normal sm:text-xl lg:text-2xl xl:text-2-5xl text-text-secondary1"></p>
                <p className="mt-6 text-lg font-normal sm:text-xl lg:text-2xl xl:text-2-5xl text-text-secondary1">
                  I'm interested in UI Design, Learning New Things & Building
                  Side Projects.
                  {/* I am a self motivated learner who enjoys
                  collaboration. I look forward to opportunities to create
                  meaningful products. */}
                </p>

                <div className="mt-6">
                  <p className="mb-2 text-lg font-semibold sm:text-xl md:mb-0 lg:text-2xl xl:text-2-5xl text-text-secondary1">
                    I love
                  </p>
                  <ul className="flex flex-wrap justify-center text-sm font-normal sm:text-base md:justify-start md:text-left md:text-base lg:text-2xl text-text-secondary1 mt-7 skill-list">
                    <li>HTML5</li>
                    <li>React</li>
                    <li>Tailwind</li>
                    <li>Firebase</li>
                    <li>UI/UX</li>
                    <li>Node</li>
                    <li>Express.js</li>
                    <li>GraphQL</li>
                  </ul>
                </div>
              </div>
              <div className="flex flex-col items-end mb-3 md:w-1/2 md:mb-0">
                <div className="relative flex flex-col items-center w-full h-full md:items-end">
                  <Img
                    fluid={src}
                    alt="There used to be picture here of me"
                    className="mb-4 rounded xl:flex-1 max-w-about md:mb-0 sm:max-w-sm md:max-w-sm lg:max-w-md xl:max-w-min-lg"
                    title="I know that was a bad joke :P"
                  />
                  <div className="block md:hidden">
                    <Nanum>This is how I smile when I fix a bug. JK :)</Nanum>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </section>
  )
}
