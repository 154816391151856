import { graphql, Link, useStaticQuery } from "gatsby"
import React from "react"
import About from "../components/About"
import Contact from "../components/Contact"
import Footer from "../components/Footer"
import Hero from "../components/Hero"
import Layout from "../components/layout"
import Projects from "../components/Projects"
import "../index.css"
// import Image from "../components/image"
import SEO from "../components/seo"
const GlobalState = React.createContext({
  designation: "Front-End Developer",
})
const store = {
  designation: "Front-End Developer",
}
const NotificationPopup = ({ children }) => {
  return <Link className="notification-popup">{children}</Link>
}
const IndexPage = () => {
  const d = useStaticQuery(graphql`
    query GET_SITE_TITLE {
      site {
        siteMetadata {
          title
          siteTitle
        }
      }
      hero_image: file(name: { eq: "Anurag" }) {
        childImageSharp {
          img: fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid

            src
          }
        }
      }
      about_image: file(name: { eq: "about-image" }) {
        childImageSharp {
          img: fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid

            src
          }
        }
      }
    }
  `)
  // const [percentage] = useScroll(65)
  return (
    <GlobalState.Provider store={store}>
      <Layout title={d.site.siteMetadata.siteTitle || "Anurag"}>
        <SEO title={d.site.siteMetadata.title} />
        <Hero src={d.hero_image.childImageSharp.img} />
        <Projects />
        <About src={d.about_image.childImageSharp.img} />
        <Footer />
      </Layout>
    </GlobalState.Provider>
  )
}

export default IndexPage
