import React from "react"
export const LinkedInIcon = () => (
  <svg
    className="w-10 h-10 transition-all duration-200 hover:opacity-75"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M36.514 3.413H5.119c-.755 0-1.365.61-1.365 1.365v31.395c0 .755.61 1.365 1.365 1.365h31.395c.755 0 1.365-.61 1.365-1.365V4.778c0-.755-.61-1.365-1.365-1.365zM13.876 32.49H8.813V16.205h5.063v16.286zm-2.53-18.512a2.935 2.935 0 112.935-2.935 2.94 2.94 0 01-2.934 2.935zM32.834 32.49h-5.06v-7.92c0-1.89-.034-4.318-2.631-4.318-2.632 0-3.037 2.056-3.037 4.18v8.058H17.05V16.205h4.854v2.227h.068c.674-1.28 2.325-2.632 4.79-2.632 5.128 0 6.07 3.374 6.07 7.76v8.931z"
      fill="#fff"
      fillOpacity=".9"
    />
  </svg>
)
export const GithubIcon = () => (
  <svg
    className="w-10 h-10 transition-all duration-200 hover:opacity-75"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.472 3.051c-9.89-.004-17.9 8.002-17.9 17.884 0 7.814 5.011 14.456 11.99 16.896.94.236.795-.432.795-.888v-3.1c-5.427.637-5.646-2.955-6.01-3.554-.736-1.256-2.476-1.576-1.956-2.176 1.236-.636 2.495.16 3.955 2.316 1.056 1.563 3.115 1.3 4.16 1.04.227-.94.715-1.78 1.387-2.432-5.623-1.008-7.966-4.439-7.966-8.518 0-1.98.652-3.8 1.931-5.267-.816-2.42.076-4.49.196-4.799 2.324-.207 4.739 1.664 4.927 1.812 1.32-.356 2.827-.544 4.515-.544 1.696 0 3.207.196 4.539.556.452-.344 2.691-1.951 4.85-1.756.117.308.988 2.332.22 4.72 1.296 1.47 1.956 3.306 1.956 5.29 0 4.087-2.36 7.522-7.998 8.514a5.099 5.099 0 011.524 3.639v4.499c.032.36 0 .716.6.716 7.082-2.388 12.18-9.078 12.18-16.96 0-9.886-8.013-17.888-17.895-17.888z"
      fill="#fff"
      fillOpacity=".9"
    />
  </svg>
)
export const InstagramIcon = () => (
  <svg
    className="w-10 h-10 transition-all duration-200 hover:opacity-75"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.5 12.273a8.19 8.19 0 00-8.201 8.202 8.19 8.19 0 008.202 8.202 8.19 8.19 0 008.202-8.202 8.19 8.19 0 00-8.202-8.202zm0 13.533a5.342 5.342 0 01-5.33-5.331 5.342 5.342 0 015.33-5.33 5.342 5.342 0 015.331 5.33 5.342 5.342 0 01-5.33 5.33zm8.539-15.78c-1.06 0-1.916.855-1.916 1.915s.856 1.916 1.916 1.916a1.91 1.91 0 001.915-1.916 1.912 1.912 0 00-1.915-1.915zm7.45 10.449c0-2.207.02-4.395-.104-6.598-.124-2.56-.708-4.831-2.58-6.703-1.875-1.875-4.142-2.455-6.702-2.58-2.207-.123-4.395-.103-6.598-.103-2.208 0-4.395-.02-6.599.104-2.559.124-4.83.708-6.702 2.58-1.875 1.875-2.455 4.142-2.58 6.702-.123 2.207-.103 4.395-.103 6.598 0 2.203-.02 4.395.104 6.598.124 2.56.708 4.831 2.58 6.703 1.875 1.875 4.142 2.455 6.701 2.58 2.208.123 4.395.103 6.599.103 2.207 0 4.395.02 6.598-.104 2.56-.124 4.831-.708 6.703-2.58 1.875-1.875 2.455-4.142 2.579-6.702.128-2.203.104-4.39.104-6.598zm-3.52 9.43a5.083 5.083 0 01-1.207 1.831 5.117 5.117 0 01-1.832 1.208c-2.103.836-7.098.648-9.43.648-2.33 0-7.33.188-9.433-.644a5.082 5.082 0 01-1.831-1.208 5.116 5.116 0 01-1.208-1.831c-.832-2.108-.644-7.102-.644-9.434 0-2.331-.188-7.33.644-9.434A5.082 5.082 0 019.236 9.21a5.17 5.17 0 011.831-1.208c2.104-.832 7.102-.644 9.434-.644 2.331 0 7.33-.188 9.433.644a5.082 5.082 0 011.832 1.208 5.118 5.118 0 011.208 1.831c.832 2.104.644 7.103.644 9.434 0 2.331.188 7.326-.648 9.43z"
      fill="#fff"
      fillOpacity=".9"
    />
  </svg>
)
export const TwitterIcon = () => (
  <svg
    // width="42"
    // height="41"
    className="w-10 h-10 transition-all duration-200 hover:opacity-75"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0)">
      <path
        d="M40.987 8.072a16.802 16.802 0 01-4.825 1.323 8.425 8.425 0 003.694-4.648 16.825 16.825 0 01-5.335 2.038 8.39 8.39 0 00-6.133-2.653 8.402 8.402 0 00-8.401 8.402c0 .658.074 1.3.217 1.914-6.982-.35-13.172-3.695-17.316-8.778A8.364 8.364 0 001.75 9.894a8.398 8.398 0 003.738 6.993 8.368 8.368 0 01-3.806-1.051v.105a8.406 8.406 0 006.74 8.238 8.413 8.413 0 01-3.795.144 8.41 8.41 0 007.848 5.835 16.858 16.858 0 01-10.434 3.596c-.678 0-1.347-.04-2.004-.117a23.78 23.78 0 0012.879 3.775c15.453 0 23.903-12.802 23.903-23.904 0-.365-.008-.727-.024-1.087a17.077 17.077 0 004.192-4.35"
        fill="#fff"
        fillOpacity=".9"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <path
          fill="#fff"
          transform="translate(.038 4)"
          d="M0 0h40.95v33.432H0z"
        />
      </clipPath>
    </defs>
  </svg>
)
export const DribbbleIcon = () => (
  <svg
    className="w-10 h-10 transition-all duration-200 hover:opacity-75"
    viewBox="0 0 41 41"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.5246 3.83911C11.3508 3.83911 3.88867 11.3013 3.88867 20.475C3.88867 29.6488 11.3508 37.111 20.5246 37.111C29.6984 37.111 37.1605 29.6488 37.1605 20.475C37.1605 11.3013 29.6984 3.83911 20.5246 3.83911ZM31.5259 11.5092C33.5054 13.9286 34.7051 17.0079 34.7331 20.3631C34.2653 20.2631 29.5664 19.3113 24.8396 19.9072C24.7396 19.6632 24.6396 19.4193 24.5356 19.1754C24.2397 18.4835 23.9238 17.7917 23.5919 17.1159C28.8426 14.9684 31.226 11.9171 31.5259 11.5092ZM20.5246 6.28651C24.1357 6.28651 27.4349 7.64218 29.9423 9.86564C29.6864 10.2296 27.5469 13.1048 22.4961 15.0004C20.1687 10.7214 17.5893 7.21029 17.1934 6.68242C18.2852 6.41848 19.4009 6.28651 20.5246 6.28651ZM14.4821 7.63818C14.858 8.15006 17.3974 11.6732 19.7488 15.8562C13.1024 17.6197 7.24785 17.5958 6.61201 17.5878C7.53578 13.1768 10.5071 9.51373 14.4821 7.63818ZM6.31608 20.495C6.31608 20.3511 6.32008 20.2031 6.32408 20.0591C6.94393 20.0711 13.8302 20.1591 20.9285 18.0356C21.3364 18.8314 21.7243 19.6393 22.0802 20.4471C21.8923 20.499 21.7043 20.555 21.5204 20.615C14.1902 22.9904 10.2911 29.4489 9.96719 29.9967C7.69974 27.4773 6.31608 24.1462 6.31608 20.495ZM20.5246 34.7036C17.2374 34.7036 14.2102 33.5838 11.8027 31.7043C12.0587 31.1804 14.934 25.6258 22.948 22.8305L23.04 22.7985C25.0355 27.9812 25.8593 32.3282 26.0713 33.5758C24.3183 34.3251 22.4309 34.7088 20.5246 34.7036ZM28.4627 32.2762C28.3187 31.4124 27.5629 27.2534 25.7033 22.1466C30.1583 21.4348 34.0693 22.6025 34.5532 22.7545C33.9213 26.7055 31.6539 30.1207 28.4627 32.2762Z"
      fill="white"
      fillOpacity="0.9"
    />
  </svg>
)
